import { createMemoryHistory, createRouter } from 'vue-router'

import StudentView from './Student.vue'
import FreeView from './Free.vue'

const routes = [
    { path: '/', component: StudentView },
    { path: '/free', component: FreeView },
]

const router = createRouter({
    history: createMemoryHistory(),
    routes,
})

export default router