<template>
  <div>
    <div class="header">

      <div class="logo">
        <img src="https://theoriefoutloos.nl/assets/images/brands/logo-theoriefoutloos.svg"
          alt="Theorie Foutloos Homepagina">
      </div>

      <div>
        <span class="icon" v-if="examType.id == 1">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path
              d="M135.2 117.4L109.1 192H402.9l-26.1-74.6C372.3 104.6 360.2 96 346.6 96H165.4c-13.6 0-25.7 8.6-30.2 21.4zM39.6 196.8L74.8 96.3C88.3 57.8 124.6 32 165.4 32H346.6c40.8 0 77.1 25.8 90.6 64.3l35.2 100.5c23.2 9.6 39.6 32.5 39.6 59.2V400v48c0 17.7-14.3 32-32 32H448c-17.7 0-32-14.3-32-32V400H96v48c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32V400 256c0-26.7 16.4-49.6 39.6-59.2zM128 288a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zm288 32a32 32 0 1 0 0-64 32 32 0 1 0 0 64z" />
          </svg>
        </span>

        <span class="icon" v-if="examType.id == 2">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
            <path
              d="M280 32c-13.3 0-24 10.7-24 24s10.7 24 24 24h57.7l16.4 30.3L256 192l-45.3-45.3c-12-12-28.3-18.7-45.3-18.7H64c-17.7 0-32 14.3-32 32v32h96c88.4 0 160 71.6 160 160c0 11-1.1 21.7-3.2 32h70.4c-2.1-10.3-3.2-21-3.2-32c0-52.2 25-98.6 63.7-127.8l15.4 28.6C402.4 276.3 384 312 384 352c0 70.7 57.3 128 128 128s128-57.3 128-128s-57.3-128-128-128c-13.5 0-26.5 2.1-38.7 6L418.2 128H480c17.7 0 32-14.3 32-32V64c0-17.7-14.3-32-32-32H459.6c-7.5 0-14.7 2.6-20.5 7.4L391.7 78.9l-14-26c-7-12.9-20.5-21-35.2-21H280zM462.7 311.2l28.2 52.2c6.3 11.7 20.9 16 32.5 9.7s16-20.9 9.7-32.5l-28.2-52.2c2.3-.3 4.7-.4 7.1-.4c35.3 0 64 28.7 64 64s-28.7 64-64 64s-64-28.7-64-64c0-15.5 5.5-29.7 14.7-40.8zM187.3 376c-9.5 23.5-32.5 40-59.3 40c-35.3 0-64-28.7-64-64s28.7-64 64-64c26.9 0 49.9 16.5 59.3 40h66.4C242.5 268.8 190.5 224 128 224C57.3 224 0 281.3 0 352s57.3 128 128 128c62.5 0 114.5-44.8 125.8-104H187.3zM128 384a32 32 0 1 0 0-64 32 32 0 1 0 0 64z" />
          </svg>
        </span>

        <span class="icon" v-if="examType.id == 3">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
            <path
              d="M280 32c-13.3 0-24 10.7-24 24s10.7 24 24 24h57.7l16.4 30.3L256 192l-45.3-45.3c-12-12-28.3-18.7-45.3-18.7H64c-17.7 0-32 14.3-32 32v32h96c88.4 0 160 71.6 160 160c0 11-1.1 21.7-3.2 32h70.4c-2.1-10.3-3.2-21-3.2-32c0-52.2 25-98.6 63.7-127.8l15.4 28.6C402.4 276.3 384 312 384 352c0 70.7 57.3 128 128 128s128-57.3 128-128s-57.3-128-128-128c-13.5 0-26.5 2.1-38.7 6L418.2 128H480c17.7 0 32-14.3 32-32V64c0-17.7-14.3-32-32-32H459.6c-7.5 0-14.7 2.6-20.5 7.4L391.7 78.9l-14-26c-7-12.9-20.5-21-35.2-21H280zM462.7 311.2l28.2 52.2c6.3 11.7 20.9 16 32.5 9.7s16-20.9 9.7-32.5l-28.2-52.2c2.3-.3 4.7-.4 7.1-.4c35.3 0 64 28.7 64 64s-28.7 64-64 64s-64-28.7-64-64c0-15.5 5.5-29.7 14.7-40.8zM187.3 376c-9.5 23.5-32.5 40-59.3 40c-35.3 0-64-28.7-64-64s28.7-64 64-64c26.9 0 49.9 16.5 59.3 40h66.4C242.5 268.8 190.5 224 128 224C57.3 224 0 281.3 0 352s57.3 128 128 128c62.5 0 114.5-44.8 125.8-104H187.3zM128 384a32 32 0 1 0 0-64 32 32 0 1 0 0 64z" />
          </svg>
        </span>
      </div>

      <div class="timeLine" v-if="type == 'question'">
        <span class="bar" :style="'width: ' + progress + '%'">

        </span>
      </div>



    </div>
    <div class="question">

      <div v-if="testUrl == ''">
        <h2>Kies een examen</h2>
        <div class="chooseType">

          <div @click="setType('65b877e2-18a1-40cf-9f28-78a0618cec05 ')">
            <div>
              <img src="/assets/icons/car.svg">
              <h2>Auto</h2>
            </div>
          </div>
          <div @click="setType('ccf3b1eb-68ce-4fb2-b624-23d5850ac9bc')">
            <div>
              <img src="/assets/icons/motorbike.svg">
              <h2>Motor</h2>
            </div>
          </div>
          <div @click="setType('60636800-37b4-4b03-b118-405dbb77ec67')">
            <div>
              <img src="/assets/icons/scooter.svg">
              <h2>Bromfiets</h2>
            </div>
          </div>
        </div>
      </div>
      <div v-if="testUrl">
        <Questions v-if="type == 'question'" @setAnswer="setAnswer" :question="currentQuestion" :value="value">
        </Questions>

        <div v-if="loginlink">
          <H2>Je account is aangemaakt</H2>

          <div>
            Je ontvangt van ons binnen een mailtje om account te bevestigen en instructies om in te loggen.<br><br>Wil
            je
            gelijk
            beginnen? Klik dan op onderstaande link:
            <br><br>
          </div>

          <a :href="loginlink" target="_parent" class="btn btn-aanmaken">Klik hier om direct te beginnen!</a>
        </div>
        <div v-if="type == 'done' && !loginlink">

          <div class="signup">
            <div class="visual">
              <img class="w-100" src="https://theoriefoutloos.nl/files/website-examens-maken-foto.jpg">
            </div>
            <form @submit.prevent="submitForm">
              <h2 style="color:#80bc5c">Een gratis volledig oefenexamen maken?</h2>
              <b>Maak een account aan en oefen direct!</b>
              <br><br>
              <div>
                <label for="name">Voornaam:</label>
                <input type="text" v-model="formData.first_name" id="first_name" placeholder="Voornaam" required>
              </div>

              <div>
                <label for="email">E-mail:</label>
                <input type="email" v-model="formData.email" id="email" placeholder="E-mail" required>
              </div>


              <div>
                <label for="name">Wachtwoord:</label>
                <input type="password" v-model="formData.password" id="password" placeholder="Wachtwoord" required>
              </div>

              <div>
                <label for="name">Wachtwoord nogmaal:</label>
                <input type="password" v-model="formData.password2" id="password2" placeholder="Wachtwoord nogmaals"
                  required>
              </div>




              <div v-if="errorMessage">
                <div class="error w-100">{{ errorMessage }}</div>
              </div>

              <div>
                <label></label>
                <button type="submit" class="btn btn-aanmaken">Account aanmaken</button>
              </div>

            </form>
          </div>
        </div>
        <nav v-if="type != 'done' && type != 'login'">

          <button v-if="previousQuestion !== false" class="prev" v-on:click="prev">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
              <path
                d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
            </svg>
            Vorige
          </button>

          <span v-if="previousQuestion === false">&nbsp;</span>
          <button class="next" v-on:click="next">
            Volgende
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
              <path
                d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" />
            </svg>
          </button>

        </nav>
      </div>

    </div>
  </div>
</template>


<script>
import axios from 'axios';
import Questions from './components/Questions.vue';


const apiUrl = window.apiUrl;
export default {
  name: 'App',
  components: {
    Questions
  },
  data() {
    return {
      formData: {

      },
      previousQuestion: false,
      cat: [],
      questionKey: 0,
      amountQuestions: 0,
      title: '',
      testUrl: '',
      progress: 0,
      duration: 0,
      errorMessage: '',
      type: '',
      page: [],
      question: [],
      allAnswers: [],
      currentAnswer: [],
      currentQuestion: [],
      examType: false,
      exameName: '',
      loginlink: ''
    };
  },
  created() {
    this.fetchData();
    this.updateProgressTimer = setInterval(this.updateProgress, 100);
  },
  beforeUnmount() {
    clearInterval(this.updateProgressTimer);
  },
  methods: {
    prev() {
      this.questionKey = this.questionKey - 1;
      this.fetchData();
    },
    setAnswer(answer) {
      this.currentAnswer = answer;
    },

    async submitForm() {


      if (this.formData.password != this.formData.password2) {
        this.errorMessage = 'Wachwoord komt niet overeen';
        return false;
      }
      let data = new FormData();
      data.append('first_name', this.formData.first_name);
      data.append('password', this.formData.password);
      data.append('email', this.formData.email);
      data.append('type', this.exameName);

      axios.request({
        method: 'post',
        url: apiUrl + '/students/add',
        data: data
      }).then((response) => {

        if (response.data.success) {

          this.loginlink = response.data.redirect_url;
          this.type = 'login';
        } else {

          this.errorMessage = 'Dit e-mailadres bestaat al.';

        }
      });

    },

    async setType(type) {
      this.exameName = type;
      this.testUrl = 'https://api.theoriefoutloos.nl/voorbeeld-examen/' + type;
      this.fetchData();
    },
    next() {
      if (this.type == 'intro') {
        this.fetchData();
        return false;
      }
      if (this.amountQuestions > this.questionKey + 1) {
        this.questionKey = this.questionKey + 1;
        this.fetchData();
      } else {
        this.type = 'done';
      }
      this.previousQuestion = true;
    },

    async fetchData() {

      let response;

      response = await axios.get(this.testUrl);

      this.type = response.data.type
      this.examType = response.data.examType
      this.type = 'question';


      if (!this.examType) {
        this.examType = 1;
      }


      this.currentQuestion = response.data.questions[this.questionKey];
      this.amountQuestions = response.data.questions.length;

      this.duration = 30;

      if (this.duration) {
        this.startTime = Math.round(new Date().getTime() / 1000);
        // this.updateProgress();
      }
    },
    updateProgress() {
      const currentTime = Math.round(new Date().getTime() / 1000);
      const progressCount = currentTime - this.startTime;
      const precentage = Math.round(progressCount / this.duration * 100);

      this.progress = precentage;
      this.progressCount = progressCount;

      if (this.duration && this.progress > 99) {
        this.next();
      }

    }
  }
};
</script>